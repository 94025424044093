import React from "react";
import { Container, Typography, Box, Paper, Button } from "@mui/material";
import image from "./../assets/images/lake-camping.webp";
import { Link } from "react-router-dom";

const AboutPage = () => {
  return (
    <Container maxWidth="xl" sx={{ mt: 8, mb: 8, px: { xs: 2, sm: 4 } }}>
      {/* Hero Section */}
      <Box
        sx={{
          width: "100%",
          minHeight: "100vh",
          backgroundImage: `url(${image})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          color: "white",
          textAlign: "center",
          borderRadius: "15px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            zIndex: 1,
          }}
        />
        <Box
          sx={{
            position: "relative",
            zIndex: 2,
            px: { xs: 2, sm: 4 },
            maxWidth: "800px",
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontWeight: 700,
              fontSize: { xs: "2rem", sm: "3.5rem" },
              marginBottom: 3,
              color: "white",
            }}
          >
            ParcelMint
          </Typography>
          <Typography
            variant="h6"
            sx={{
              fontSize: { xs: "1rem", sm: "1.25rem" },
              marginBottom: 4,
            }}
          >
            Redefiniendo la propiedad de terrenos con innovación, accesibilidad
            y precios asequibles. Adquiere tu parcela hoy.
          </Typography>
        </Box>
      </Box>

      {/* Main Content Section */}
      <Paper
        elevation={3}
        sx={{
          mt: 6,
          py: 6,
          px: { xs: 3, sm: 6 },
          borderRadius: "15px",
          backgroundColor: "#f9f9f9",
        }}
      >
        <Box sx={{ textAlign: "center", mb: 6 }}>
          <Typography
            variant="h3"
            color="primary"
            fontWeight="700"
            gutterBottom
          >
            Acerca de ParcelMint
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{ maxWidth: "800px", margin: "0 auto" }}
          >
            ParcelMint está transformando la propiedad de terrenos. Nuestra
            plataforma te permite adquirir parcelas de forma simple y sin
            complicaciones, con precios accesibles y sin intereses. Cada parcela
            viene con beneficios como acceso a áreas comunes, actividades
            recreativas y una comunidad exclusiva de propietarios.
          </Typography>
        </Box>

        {/* Mission Section */}
        <Box sx={{ mb: 6 }}>
          <Typography
            variant="h4"
            color="primary"
            fontWeight="700"
            gutterBottom
            textAlign="center"
          >
            Nuestra Misión
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{
              maxWidth: "800px",
              margin: "0 auto",
              textAlign: "justify",
              fontSize: { xs: "1rem", sm: "1.1rem" },
            }}
          >
            Nuestra misión es ofrecer parcelas accesibles y asequibles que
            permitan a cualquiera convertirse en propietario de tierras. Con
            ParcelMint, eliminamos las barreras financieras y ofrecemos una
            forma innovadora de poseer terrenos sin las complicaciones
            tradicionales.
          </Typography>
        </Box>

        {/* Vision Section */}
        <Box sx={{ mb: 6 }}>
          <Typography
            variant="h4"
            color="primary"
            fontWeight="700"
            gutterBottom
            textAlign="center"
          >
            Nuestra Visión
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{
              maxWidth: "800px",
              margin: "0 auto",
              textAlign: "justify",
              fontSize: { xs: "1rem", sm: "1.1rem" },
            }}
          >
            En ParcelMint, imaginamos un futuro donde cualquier persona pueda
            tener acceso a la propiedad de tierras. Ofrecemos transacciones
            transparentes y seguras para facilitar la compra y gestión de
            parcelas. Nuestra visión es democratizar los bienes raíces y crear
            una comunidad donde cada propietario pueda disfrutar de su tierra y
            maximizar su valor.
          </Typography>
        </Box>

        {/* Call to Action */}
        <Box sx={{ textAlign: "center", mt: 4 }}>
          <Typography
            variant="h5"
            color="primary"
            fontWeight="700"
            gutterBottom
          >
            Sé Parte de Esta Revolución
          </Typography>
          <Typography
            variant="body1"
            color="text.secondary"
            sx={{
              maxWidth: "800px",
              margin: "0 auto",
              marginBottom: 4,
              fontSize: { xs: "1rem", sm: "1.1rem" },
            }}
          >
            Con ParcelMint, poseer una parcela no es solo un sueño, es una
            realidad al alcance de todos. Únete a nuestra comunidad y haz de la
            propiedad de terrenos algo personal y accesible.
          </Typography>
          <Button
            component={Link}
            to="/properties"
            variant="contained"
            sx={{
              backgroundColor: "#0078D4",
              color: "white",
              padding: "10px 30px",
              borderRadius: "30px",
              fontSize: "1rem",
              "&:hover": { backgroundColor: "#005FA3" },
            }}
          >
            Explorar Parcelas
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default AboutPage;
