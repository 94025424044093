import React, { useState } from "react";
import { Box, TextField, Typography, Grid, Container } from "@mui/material";

const AreaCalculator = () => {
  const [squareFeet, setSquareFeet] = useState("");
  const [squareVaras, setSquareVaras] = useState("");
  const [squareMeters, setSquareMeters] = useState("");

  const handleSquareFeetChange = (event) => {
    const value = Number(event.target.value);
    setSquareFeet(value);
    setSquareVaras((value / 9).toFixed(2)); // Conversión: 1 vara cuadrada = 9 pies cuadrados
    setSquareMeters((value / 10.7639).toFixed(2)); // Conversión: 1 metro cuadrado = 10.7639 pies cuadrados
  };

  const handleSquareVarasChange = (event) => {
    const value = Number(event.target.value);
    setSquareVaras(value);
    setSquareFeet((value * 9).toFixed(2)); // Conversión: 1 vara cuadrada = 9 pies cuadrados
    setSquareMeters(((value * 9) / 10.7639).toFixed(2)); // Conversión indirecta a través de pies cuadrados
  };

  const handleSquareMetersChange = (event) => {
    const value = Number(event.target.value);
    setSquareMeters(value);
    setSquareFeet((value * 10.7639).toFixed(2)); // Conversión: 1 metro cuadrado = 10.7639 pies cuadrados
    setSquareVaras(((value * 10.7639) / 9).toFixed(2)); // Conversión indirecta a través de pies cuadrados
  };

  return (
    <Container maxWidth="sm" sx={{ py: 4 }}>
      <Typography variant="h4" textAlign="center" gutterBottom>
        Calculadora de Unidades de Área
      </Typography>
      <Typography
        variant="body1"
        textAlign="center"
        color="text.secondary"
        gutterBottom
      >
        Convierte rápidamente entre pies cuadrados, varas cuadradas y metros
        cuadrados para cálculos de terreno.
      </Typography>
      <Box sx={{ mt: 4 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TextField
              label="Pies Cuadrados"
              type="number"
              value={squareFeet}
              onChange={handleSquareFeetChange}
              fullWidth
              sx={{
                "& .MuiInputLabel-root": { color: "black" }, // Label color
                "& .MuiOutlinedInput-root": {
                  "& input": { color: "black" }, // Input text color
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Varas Cuadradas"
              type="number"
              value={squareVaras}
              onChange={handleSquareVarasChange}
              fullWidth
              sx={{
                "& .MuiInputLabel-root": { color: "black" }, // Label color
                "& .MuiOutlinedInput-root": {
                  "& input": { color: "black" }, // Input text color
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              label="Metros Cuadrados"
              type="number"
              value={squareMeters}
              onChange={handleSquareMetersChange}
              fullWidth
              sx={{
                "& .MuiInputLabel-root": { color: "black" }, // Label color
                "& .MuiOutlinedInput-root": {
                  "& input": { color: "black" }, // Input text color
                },
              }}
            />
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
};

export default AreaCalculator;
