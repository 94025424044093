import React from "react";

function NoFound() {
  return (
    <div>
      <h1>No found</h1>
    </div>
  );
}
export default NoFound;
