import React from "react";
import { Box, CircularProgress, Typography } from "@mui/material";

const Loading = ({ message }) => (
  <Box
    display="flex"
    flexDirection="column"
    alignItems="center"
    justifyContent="center"
    height="100vh"
  >
    <CircularProgress />
    <Typography variant="body1" mt={2}>
      {message}
    </Typography>
  </Box>
);

export default Loading;
