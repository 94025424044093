import React from "react";
import {
  Box,
  Container,
  Typography,
  Button,
  Grid,
  Card,
  CardContent,
  Paper,
} from "@mui/material";
import pmth4 from "./../assets/images/pmt4.PNG";
import { Link } from "react-router-dom";

const HomePage = () => {
  return (
    <Container maxWidth={false} disableGutters>
      {/* Hero Section */}
      <Box
        sx={{
          width: "100%",
          height: "100vh",
          backgroundImage: `url(${pmth4})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "column",
          position: "relative",
          color: "white",
          textAlign: "center",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0.6)",
            zIndex: 1,
          }}
        />
        <Box
          sx={{
            position: "relative",
            zIndex: 2,
            textAlign: "center",
            paddingX: { xs: 2, sm: 4 },
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontWeight: 700,
              fontSize: { xs: "2.5rem", sm: "4rem" },
              marginBottom: 2,
              color: "white",
            }}
          >
            Bienvenido a ParcelMint
          </Typography>
          <Typography
            variant="h6"
            sx={{
              maxWidth: "600px",
              margin: "0 auto",
              fontSize: { xs: "1rem", sm: "1.5rem" },
              marginBottom: 4,
            }}
          >
            Descubre una nueva manera de ser propietario de terrenos. Compra
            parcelas accesibles con facilidad y sin intereses.
          </Typography>
          <Button
            component={Link}
            to={"/properties"}
            variant="contained"
            sx={{
              backgroundColor: "#0078D4",
              color: "white",
              padding: "10px 30px",
              borderRadius: "30px",
              fontSize: "1rem",
              "&:hover": { backgroundColor: "#005FA3" },
            }}
          >
            Explorar Parcelas
          </Button>
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: "10px",
            left: "50%",
            transform: "translateX(-50%)",
            color: "white",
            fontSize: "1.25rem",
          }}
        >
          ⬇️ Desplázate hacia abajo
        </Box>
      </Box>

      {/* Key Features Section */}
      {[
        "Parcelas Accesibles",
        "Sin Complicaciones",
        "Propiedad Total",
        "Ubicación Estratégica",
      ].map((title, index) => (
        <Box
          key={index}
          sx={{
            height: "100vh",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: index % 2 === 0 ? "#f5f5f5" : "#ffffff",
            textAlign: "center",
            paddingX: { xs: 3, sm: 6 },
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontWeight: 700,
              fontSize: { xs: "2rem", sm: "2.5rem" },
              marginBottom: 3,
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              maxWidth: "600px",
              fontSize: { xs: "1rem", sm: "1.25rem" },
              color: "text.secondary",
            }}
          >
            {index === 0 &&
              "Compra parcelas de 464 metros cuadrados o más a precios increíbles, sin intereses."}
            {index === 1 &&
              "Facilitamos todo el proceso de compra para que ser propietario sea rápido y sencillo."}
            {index === 2 &&
              "Obtén un título digital y control total de tu parcela dentro de una comunidad exclusiva."}
            {index === 3 &&
              "Propiedades seleccionadas cuidadosamente con acceso a recursos como playas y áreas comunes."}
          </Typography>
          <Button
            component={Link}
            to={"/properties"}
            variant="outlined"
            sx={{
              marginTop: 4,
              borderRadius: "30px",
              padding: "10px 20px",
              fontSize: "1rem",
              "&:hover": { backgroundColor: "#0078D4", color: "white" },
            }}
          >
            Explorar Más
          </Button>
        </Box>
      ))}

      {/* Call to Action */}
      <Paper
        elevation={3}
        sx={{
          padding: { xs: 4, sm: 8 },
          textAlign: "center",
          backgroundColor: "#000",
          color: "white",
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
            fontSize: { xs: "1.5rem", sm: "2rem" },
            marginBottom: 3,
          }}
        >
          ¿Listo para ser dueño de tu parcela?
        </Typography>
        <Typography
          variant="body1"
          sx={{
            maxWidth: "600px",
            margin: "0 auto",
            marginBottom: 4,
            fontSize: { xs: "1rem", sm: "1.25rem" },
          }}
        >
          Haz realidad tu sueño de ser propietario hoy con ParcelMint. Únete a
          una comunidad innovadora y sin complicaciones.
        </Typography>
        <Button
          component={Link}
          to={"/properties"}
          variant="contained"
          sx={{
            backgroundColor: "white",
            color: "black",
            padding: "10px 30px",
            borderRadius: "30px",
            "&:hover": { backgroundColor: "#F1F1F1" },
          }}
        >
          Comienza Ahora
        </Button>
      </Paper>
    </Container>
  );
};

export default HomePage;
