import axios from "axios";

export default {
  // Create a new property
  create: function (data) {
    console.log("Creating new property:", data);
    return axios.post("/api/property/", data);
  },

  // Fetch all property
  getAll: function () {
    return axios.get("/api/property");
  },

  // Fetch a property by ID
  getById: function (id) {
    console.log("Fetching property with ID:", id);
    return axios.get(`/api/property/property-details/${id}`);
  },

  // Update a property by ID
  update: function (id, data) {
    console.log("Updating property with ID:", id, "Data:", data);
    return axios.put(`/api/property/${id}`, data);
  },

  // Delete a property by ID
  delete: function (id) {
    console.log("Deleting property with ID:", id);
    return axios.delete(`/api/property/${id}`);
  },
  // buying a new property
  purchase: function (data) {
    console.log("buying new property:", data);
    return axios.post("/api/property/purchase", data);
  },
  // buying a new property
  purchase: function (data) {
    console.log("buying new property:", data);

    return axios.post("/api/property/capturePayment", data);
  },
  // Fetch a property by ID
  getParcelById: function (id) {
    console.log("Fetching property with ID:", id);
    return axios.get(`/api/property/parcel/${id}/`);
  },
};
