import { io } from "socket.io-client";

const createSocket = (token) => {
  const serverUrl =
    process.env.NODE_ENV === "production"
      ? "https://www.parcelmint.com" // Production server URL
      : "http://localhost:5000"; // Development server URL

  return io(serverUrl, {
    auth: {
      token, // Send the token as part of the auth handshake
    },
    transports: ["websocket", "polling"], // Ensure fallback to polling if WebSocket is unavailable
    reconnection: true, // Enable reconnection
    reconnectionAttempts: 5, // Number of retries
    reconnectionDelay: 1000, // Delay between retries
  });
};

export default createSocket;
