import React from "react";
import { Container, Typography, Box, Link } from "@mui/material";

const TermsAndConditions = () => {
  return (
    <Container
      maxWidth="md"
      sx={{
        padding: "2rem",
        fontFamily:
          "'San Francisco', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif",
        lineHeight: 1.8,
      }}
    >
      {/* Title Section */}
      <Box sx={{ textAlign: "center", marginBottom: "2rem" }}>
        <Typography
          variant="h3"
          component="h1"
          sx={{ fontWeight: "bold", marginBottom: "1rem" }}
        >
          Terms and Conditions
        </Typography>
        <Typography variant="body2" sx={{ color: "text.secondary" }}>
          Last Updated: [Insert Date]
        </Typography>
      </Box>

      {/* Content Section */}
      <Box>
        <Typography
          variant="h5"
          component="h2"
          sx={{ fontWeight: "bold", marginBottom: "1rem" }}
        >
          1. Introduction
        </Typography>
        <Typography variant="body1" paragraph>
          Welcome to ParcelMint. These Terms and Conditions outline the rules
          and regulations for using our platform and services. By accessing or
          using ParcelMint, you agree to comply with these terms. If you do not
          agree, please refrain from using our platform.
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          sx={{ fontWeight: "bold", marginBottom: "1rem" }}
        >
          2. Use of Services
        </Typography>
        <Typography variant="body1" paragraph>
          You are granted a limited, non-exclusive, non-transferable right to
          use our platform solely for personal or internal business purposes.
          Any unauthorized use of the platform is strictly prohibited and may
          result in termination of access.
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          sx={{ fontWeight: "bold", marginBottom: "1rem" }}
        >
          3. User Responsibilities
        </Typography>
        <Typography variant="body1" paragraph>
          By using ParcelMint, you agree to:
        </Typography>
        <Typography variant="body1" component="ul" sx={{ paddingLeft: "2rem" }}>
          <li>
            Provide accurate, current, and complete information when creating an
            account.
          </li>
          <li>
            Maintain the security and confidentiality of your account
            credentials.
          </li>
          <li>
            Refrain from engaging in illegal activities or activities that
            disrupt our services.
          </li>
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          sx={{ fontWeight: "bold", marginBottom: "1rem" }}
        >
          4. Intellectual Property
        </Typography>
        <Typography variant="body1" paragraph>
          All content, including but not limited to text, graphics, logos, and
          software, is the property of ParcelMint or its licensors and is
          protected by applicable intellectual property laws. You may not copy,
          reproduce, distribute, or create derivative works without our explicit
          consent.
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          sx={{ fontWeight: "bold", marginBottom: "1rem" }}
        >
          5. Payment and Transactions
        </Typography>
        <Typography variant="body1" paragraph>
          Payments made through ParcelMint are processed securely. You are
          responsible for ensuring that all payment information provided is
          accurate. Refunds or cancellations, if applicable, will be handled in
          accordance with our refund policy.
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          sx={{ fontWeight: "bold", marginBottom: "1rem" }}
        >
          6. Limitation of Liability
        </Typography>
        <Typography variant="body1" paragraph>
          ParcelMint shall not be liable for any direct, indirect, incidental,
          or consequential damages arising from your use of the platform or
          inability to use the platform, including but not limited to loss of
          data, revenue, or profits.
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          sx={{ fontWeight: "bold", marginBottom: "1rem" }}
        >
          7. Amendments
        </Typography>
        <Typography variant="body1" paragraph>
          ParcelMint reserves the right to update or modify these Terms and
          Conditions at any time. Changes will be posted on this page, and
          continued use of the platform constitutes acceptance of the revised
          terms.
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          sx={{ fontWeight: "bold", marginBottom: "1rem" }}
        >
          8. Governing Law
        </Typography>
        <Typography variant="body1" paragraph>
          These Terms and Conditions shall be governed by and construed in
          accordance with the laws of [Insert Jurisdiction].
        </Typography>

        <Typography
          variant="h5"
          component="h2"
          sx={{ fontWeight: "bold", marginBottom: "1rem" }}
        >
          9. Contact Information
        </Typography>
        <Typography variant="body1" paragraph>
          If you have any questions or concerns regarding these Terms, please
          contact us at{" "}
          <Link
            href="mailto:support@parcelmint.com"
            sx={{ textDecoration: "none", color: "primary.main" }}
          >
            support@parcelmint.com
          </Link>
          .
        </Typography>
      </Box>
    </Container>
  );
};

export default TermsAndConditions;
