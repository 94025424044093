import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Container,
  CircularProgress,
  Grid,
  Card,
  CardContent,
} from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import GoBackButton from "../../../components/goBackButton";

const TransactionHistory = () => {
  const { userId } = useParams(); // Get userId from route params
  const [transactions, setTransactions] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const response = await axios.get(`/api/transactions/user/${userId}`);
        setTransactions(response.data);
        console.log("data ", response.data);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchTransactions();
  }, [userId]);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      <GoBackButton />
      <Typography
        variant="h3"
        align="center"
        gutterBottom
        sx={{
          fontWeight: 700,
          color: "primary.main",
          marginBottom: 4,
        }}
      >
        Transaction History
      </Typography>

      {transactions.length === 0 ? (
        <Typography
          variant="h5"
          align="center"
          color="text.secondary"
          sx={{ marginTop: 4 }}
        >
          No transactions found.
        </Typography>
      ) : (
        <Grid container spacing={4}>
          {transactions.map((transaction) => (
            <Grid item xs={12} sm={6} md={4} key={transaction._id}>
              <Card
                elevation={3}
                sx={{
                  borderRadius: "15px",
                  boxShadow: 3,
                  transition: "transform 0.3s",
                  "&:hover": { transform: "scale(1.05)" },
                }}
              >
                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 2,
                    padding: 3,
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontWeight: 700,
                      color: "primary.main",
                      marginBottom: 1,
                    }}
                  >
                    Property: {transaction.propertyId?.title || "N/A"}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ fontSize: "0.9rem" }}
                  >
                    <strong>Transaction ID:</strong> {transaction.transactionId}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ fontSize: "0.9rem" }}
                  >
                    <strong>Total Amount:</strong> ${transaction.totalAmount}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ fontSize: "0.9rem" }}
                  >
                    <strong>Payment Method:</strong> {transaction.paymentMethod}
                  </Typography>
                  <Typography
                    variant="body2"
                    color="text.secondary"
                    sx={{ fontSize: "0.9rem" }}
                  >
                    <strong>Date:</strong>{" "}
                    {new Date(transaction.date).toLocaleDateString()}
                  </Typography>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default TransactionHistory;
