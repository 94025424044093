import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "./../../firebase/AuthContext";
import { getAuth } from "firebase/auth";
import createSocket from "./../../SocketConnection /socket";

const PropertyMessaging = ({ propertyId, ownerId, parcelId }) => {
  const { user, getToken } = useContext(AuthContext);
  const [messages, setMessages] = useState([]);
  const [messageContent, setMessageContent] = useState("");
  const [socket, setSocket] = useState(null);
  const [conversationId, setConversationId] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  // Initialize socket connection
  useEffect(() => {
    const setupSocket = async () => {
      try {
        const token = await getToken();
        const newSocket = createSocket(token);
        setSocket(newSocket);

        newSocket.on("connect", () => {
          console.log("Socket connected:", newSocket.id);
        });

        newSocket.on("receiveMessage", (message) => {
          console.log("Message received:", message);
          setMessages((prevMessages) => [...prevMessages, message]);
        });

        newSocket.on("connect_error", (error) => {
          console.error("Socket connection error:", error.message);
        });

        return () => {
          newSocket.disconnect();
          console.log("Socket disconnected");
        };
      } catch (err) {
        console.error("Failed to setup socket:", err.message);
        setError("Failed to connect to chat server.");
      }
    };

    setupSocket();
  }, [getToken]);

  console.log("Conversation ID:", conversationId);

  // Fetch messages for the current property and conversation
  const fetchMessages = async () => {
    setLoading(true);
    try {
      const token = await getToken();
      const response = await fetch(
        `/api/messages/${parcelId}/${user._id}/${ownerId}/${propertyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch messages.");
      }

      const data = await response.json();
      console.log("Fetched messages data:", data);

      setMessages(data.messages || []);

      // Derive conversationId from the first message if not provided explicitly
      const derivedConversationId =
        data.conversationId ||
        (data.messages[0] && data.messages[0].conversationId) ||
        "";
      console.log("Derived conversationId:", derivedConversationId);
      setConversationId(derivedConversationId);
    } catch (err) {
      console.error("Failed to fetch messages:", err.message);
      setError("Failed to load messages.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch messages on component load
  useEffect(() => {
    if (user) {
      fetchMessages();
    }
  }, [parcelId, user]);

  // Create a message with backend-generated conversationId
  const createMessage = async (messageData) => {
    try {
      const token = await getToken();
      const response = await fetch(`/api/messages/send`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(messageData),
      });

      if (!response.ok) {
        throw new Error("Failed to send message.");
      }

      const data = await response.json();
      console.log("Message created:", data);

      // Set the generated conversationId if it doesn't exist
      if (!conversationId) {
        setConversationId(data.conversationId);
      }

      setMessages((prevMessages) => [...prevMessages, data]);
      setMessageContent("");
    } catch (err) {
      console.error("Error sending message:", err.message);
      setError("Failed to send message.");
    }
  };

  // Send a message
  const sendMessage = () => {
    if (!socket || !socket.connected) {
      console.error("Socket is not connected.");
      return;
    }

    const messageData = {
      senderId: user._id,
      receiverId: ownerId,
      content: messageContent.trim(),
      propertyId,
      parcelId,
      conversationId,
    };

    console.log("Sending message:", messageData);

    // Handle message creation
    createMessage(messageData);
  };

  return (
    <div>
      <h3>Chat with Property Owner</h3>
      {loading ? (
        <p>Loading messages...</p>
      ) : (
        <div
          style={{
            border: "1px solid #ccc",
            padding: "10px",
            maxHeight: "400px",
            overflowY: "auto",
          }}
        >
          {messages.length > 0 ? (
            messages.map((msg, index) => (
              <div
                key={index}
                style={{
                  marginBottom: "10px",
                  textAlign: msg.senderId === user._id ? "right" : "left",
                }}
              >
                <strong>{msg.senderId === user._id ? "You" : "Owner"}:</strong>{" "}
                {msg.content}
                <div style={{ fontSize: "0.8em", color: "#888" }}>
                  {new Date(msg.createdAt).toLocaleString()}
                </div>
              </div>
            ))
          ) : (
            <p>No messages yet. Start the conversation!</p>
          )}
        </div>
      )}
      <div style={{ marginTop: "20px" }}>
        <input
          type="text"
          value={messageContent}
          onChange={(e) => setMessageContent(e.target.value)}
          placeholder="Type a message..."
          style={{
            width: "80%",
            padding: "10px",
            borderRadius: "5px",
            border: "1px solid #ccc",
          }}
        />
        <button
          onClick={sendMessage}
          disabled={!messageContent.trim()}
          style={{
            padding: "10px 20px",
            marginLeft: "10px",
            backgroundColor: !messageContent.trim() ? "#ccc" : "#007bff",
            color: "#fff",
            border: "none",
            borderRadius: "5px",
            cursor: !messageContent.trim() ? "not-allowed" : "pointer",
          }}
        >
          Send
        </button>
      </div>
      {error && <p style={{ color: "red" }}>{error}</p>}
    </div>
  );
};

export default PropertyMessaging;
