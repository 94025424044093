import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  TextField,
  Button,
  Typography,
  Stack,
  MenuItem,
  IconButton,
} from "@mui/material";
import { Add, Remove } from "@mui/icons-material";
import propertyApi from "./../../../../../api/property";
import { AuthContext } from "../../../../../firebase/AuthContext";
import { useNavigate } from "react-router-dom";
import Loading from "./../../../../../components/Loading";

const AddProperty = () => {
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    if (user && !user.isAdmin) {
      navigate("/");
    }
  }, [user, navigate]);

  const userId = user._id;
  const [formData, setFormData] = useState({
    userId: userId,
    title: "",
    description: "",
    price: "",
    priceUnit: "squareFoot", // Default value
    imageUrl: "",
    location: "",
    features: "",
    totalSquareFootage: "",
    availableSquareFeet: "",
    totalAcres: "",
    availableAcres: "",
    financingOptions: {
      downPayment: "",
      monthlyPayment: "",
    },

    sections: [], // Initialize sections as an empty array
    parcels: [], // Initialize parcels as an empty array
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.startsWith("financingOptions.")) {
      const field = name.split(".")[1];
      setFormData((prevData) => ({
        ...prevData,
        financingOptions: { ...prevData.financingOptions, [field]: value },
      }));
    } else {
      setFormData((prevData) => ({ ...prevData, [name]: value }));
    }
  };

  const handlePaymentTypeChange = (e) => {
    const value = e.target.value;
    setFormData((prevData) => ({
      ...prevData,
      paymentType: value,
      financingOptions:
        value === "financing"
          ? prevData.financingOptions
          : { downPayment: "", monthlyPayment: "" },
    }));
  };

  const handleSectionChange = (index, field, value) => {
    const updatedSections = [...formData.sections];
    updatedSections[index][field] = value;
    setFormData((prevData) => ({ ...prevData, sections: updatedSections }));
  };

  const addSection = () => {
    setFormData((prevData) => ({
      ...prevData,
      sections: [
        ...prevData.sections,
        { icon: "", heading: "", content: [""] },
      ],
    }));
  };

  const removeSection = (index) => {
    const updatedSections = [...formData.sections];
    updatedSections.splice(index, 1);
    setFormData((prevData) => ({ ...prevData, sections: updatedSections }));
  };

  const addContentToSection = (sectionIndex) => {
    const updatedSections = [...formData.sections];
    updatedSections[sectionIndex].content.push("");
    setFormData((prevData) => ({ ...prevData, sections: updatedSections }));
  };

  const removeContentFromSection = (sectionIndex, contentIndex) => {
    const updatedSections = [...formData.sections];
    updatedSections[sectionIndex].content.splice(contentIndex, 1);
    setFormData((prevData) => ({ ...prevData, sections: updatedSections }));
  };

  const handleContentChange = (sectionIndex, contentIndex, value) => {
    const updatedSections = [...formData.sections];
    updatedSections[sectionIndex].content[contentIndex] = value;
    setFormData((prevData) => ({ ...prevData, sections: updatedSections }));
  };

  // Handle parcels logic
  const handleParcelChange = (index, field, value) => {
    const updatedParcels = [...formData.parcels];
    updatedParcels[index][field] = value;
    setFormData((prevData) => ({ ...prevData, parcels: updatedParcels }));
  };

  const addParcel = () => {
    setFormData((prevData) => ({
      ...prevData,
      parcels: [
        ...prevData.parcels,
        { coordinates: "", area: "", price: "", estado: "en venta" },
      ],
    }));
  };

  const removeParcel = (index) => {
    const updatedParcels = [...formData.parcels];
    updatedParcels.splice(index, 1);
    setFormData((prevData) => ({ ...prevData, parcels: updatedParcels }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await propertyApi.create(formData);
      setMessage("Property added successfully!");
      setTimeout(() => navigate("/"), 2000);
    } catch (error) {
      console.error("Error adding property:", error);
      setMessage(error.response?.data?.message || "Failed to add property");
    }
  };

  const handleGoBack = () => {
    navigate(-1);
  };

  return !user ? (
    <Loading />
  ) : (
    <Box
      component="form"
      onSubmit={handleSubmit}
      sx={{
        maxWidth: 600,
        mx: "auto",
        mt: 5,
        p: 3,
        boxShadow: 3,
        borderRadius: 2,
      }}
    >
      <Button variant="contained" color="primary" onClick={handleGoBack}>
        Go Back
      </Button>
      <Typography variant="h4" mb={3} textAlign="center">
        Add New Property
      </Typography>
      {message && (
        <Box
          sx={{
            textAlign: "center",
            mb: 3,
            color: message.includes("success") ? "green" : "red",
          }}
        >
          {message}
        </Box>
      )}
      <Stack spacing={2}>
        <TextField
          label="Title"
          name="title"
          value={formData.title}
          onChange={handleChange}
          required
          fullWidth
        />
        <TextField
          label="Description"
          name="description"
          value={formData.description}
          onChange={handleChange}
          required
          multiline
          rows={3}
          fullWidth
        />
        <TextField
          label="Price"
          name="price"
          value={formData.price}
          onChange={handleChange}
          required
          fullWidth
        />
        <TextField
          select
          label="Price Unit"
          name="priceUnit"
          value={formData.priceUnit}
          onChange={handleChange}
          required
          fullWidth
        >
          <MenuItem value="squareFoot">Square Foot</MenuItem>
          <MenuItem value="acre">Acre</MenuItem>
        </TextField>
        <TextField
          label="Image URL"
          name="imageUrl"
          value={formData.imageUrl}
          onChange={handleChange}
          required
          fullWidth
        />
        <TextField
          label="Location"
          name="location"
          value={formData.location}
          onChange={handleChange}
          required
          fullWidth
        />
        <TextField
          label="Features (comma separated)"
          name="features"
          value={formData.features}
          onChange={handleChange}
          fullWidth
        />
        {formData.priceUnit === "squareFoot" && (
          <>
            <TextField
              label="Total Square Footage"
              name="totalSquareFootage"
              type="number"
              value={formData.totalSquareFootage}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              label="Available Square Feet"
              name="availableSquareFeet"
              type="number"
              value={formData.availableSquareFeet}
              onChange={handleChange}
              fullWidth
            />
          </>
        )}
        {formData.priceUnit === "acre" && (
          <>
            <TextField
              label="Total Acres"
              name="totalAcres"
              type="number"
              value={formData.totalAcres}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              label="Available Acres"
              name="availableAcres"
              type="number"
              value={formData.availableAcres}
              onChange={handleChange}
              fullWidth
            />
          </>
        )}
        <TextField
          select
          label="Payment Type"
          name="paymentType"
          value={formData.paymentType}
          onChange={handlePaymentTypeChange}
          required
          fullWidth
        >
          <MenuItem value="cash">Cash</MenuItem>
          <MenuItem value="financing">Financing</MenuItem>
        </TextField>
        {formData.paymentType === "financing" && (
          <>
            <Typography variant="h6">Financing Options</Typography>
            <TextField
              label="Down Payment"
              name="financingOptions.downPayment"
              type="number"
              value={formData.financingOptions.downPayment}
              onChange={handleChange}
              fullWidth
            />
            <TextField
              label="Monthly Payment"
              name="financingOptions.monthlyPayment"
              type="number"
              value={formData.financingOptions.monthlyPayment}
              onChange={handleChange}
              fullWidth
            />
          </>
        )}
        <Typography variant="h5">Sections</Typography>
        {formData.sections.map((section, sectionIndex) => (
          <Box
            key={sectionIndex}
            sx={{ border: "1px solid #ddd", p: 2, mb: 2 }}
          >
            <TextField
              label="Icon"
              value={section.icon}
              onChange={(e) =>
                handleSectionChange(sectionIndex, "icon", e.target.value)
              }
              fullWidth
              required
            />
            <TextField
              label="Heading"
              value={section.heading}
              onChange={(e) =>
                handleSectionChange(sectionIndex, "heading", e.target.value)
              }
              fullWidth
              required
            />
            {section.content.map((content, contentIndex) => (
              <Box key={contentIndex} sx={{ display: "flex", gap: 1, mb: 1 }}>
                <TextField
                  label={`Content ${contentIndex + 1}`}
                  value={content}
                  onChange={(e) =>
                    handleContentChange(
                      sectionIndex,
                      contentIndex,
                      e.target.value
                    )
                  }
                  fullWidth
                  required
                />
                <IconButton
                  color="error"
                  onClick={() =>
                    removeContentFromSection(sectionIndex, contentIndex)
                  }
                >
                  <Remove />
                </IconButton>
              </Box>
            ))}
            <Button
              variant="outlined"
              onClick={() => addContentToSection(sectionIndex)}
            >
              Add Content
            </Button>
            <IconButton
              color="error"
              onClick={() => removeSection(sectionIndex)}
            >
              <Remove />
            </IconButton>
          </Box>
        ))}
        <Button variant="contained" onClick={addSection}>
          Add Section
        </Button>
        {/* Parcels Section */}
        <Typography variant="h5" mt={3}>
          Parcels
        </Typography>
        {formData.parcels.map((parcel, index) => (
          <Box key={index} sx={{ border: "1px solid #ddd", p: 2, mb: 2 }}>
            <TextField
              label="Coordinates (Comma separated)"
              value={parcel.coordinates}
              onChange={(e) =>
                handleParcelChange(index, "coordinates", e.target.value)
              }
              fullWidth
              required
            />
            <TextField
              label="Area (Square Feet)"
              type="number"
              value={parcel.area}
              onChange={(e) =>
                handleParcelChange(index, "area", e.target.value)
              }
              fullWidth
              required
            />
            <TextField
              label="Price"
              type="number"
              value={parcel.price}
              onChange={(e) =>
                handleParcelChange(index, "price", e.target.value)
              }
              fullWidth
              required
            />
            <TextField
              select
              label="Status"
              value={parcel.estado}
              onChange={(e) =>
                handleParcelChange(index, "estado", e.target.value)
              }
              fullWidth
            >
              <MenuItem value="en venta">En Venta</MenuItem>
              <MenuItem value="vendida">Vendida</MenuItem>
            </TextField>
            <IconButton
              color="error"
              onClick={() => removeParcel(index)}
              sx={{ mt: 2 }}
            >
              <Remove />
            </IconButton>
          </Box>
        ))}
        <Button variant="contained" onClick={addParcel}>
          Add Parcel
        </Button>
        <Button variant="contained" color="primary" type="submit" fullWidth>
          Add Property
        </Button>
      </Stack>
    </Box>
  );
};

export default AddProperty;
