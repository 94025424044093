import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Grid,
  Button,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import property from "../../../../../api/property"; // Adjust path as needed
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../../../../../firebase/AuthContext";

// Styled Box for the page layout
const StyledContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: "#f4f4f9",
  minHeight: "100vh",
}));

// Property card styling
const PropertyCard = styled(Card)(({ theme }) => ({
  maxWidth: 400,
  borderRadius: theme.spacing(2),
  boxShadow: theme.shadows[3],
  transition: "transform 0.2s",
  "&:hover": {
    transform: "scale(1.02)",
  },
}));

const PropertyListPage = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [message, setMessage] = useState(null);
  const { user } = useContext(AuthContext);
  console.log("user add property ", user);
  useEffect(() => {
    if (user && user.isAdmin === false) {
      navigate("/");
    }
    const fetchProperties = async () => {
      try {
        const response = await property.getAll(); // Assuming `property.getAll()` fetches all properties
        console.log("properties ", response.data);
        setProperties(response.data);
      } catch (error) {
        setError("Failed to load properties");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProperties();
  }, []);

  if (loading)
    return <CircularProgress sx={{ display: "block", margin: "2rem auto" }} />;
  if (error)
    return (
      <Typography color="error" textAlign="center">
        {error}
      </Typography>
    );

  return properties.length > 0 ? (
    <StyledContainer>
      <Typography variant="h4" textAlign="center" gutterBottom>
        Available Properties
      </Typography>
      <Typography
        variant="subtitle1"
        textAlign="center"
        color="text.secondary"
        mb={4}
      >
        Explore available properties to mint and start your investment journey.
      </Typography>
      <Grid container spacing={3} justifyContent="center">
        {properties.map((property) => (
          <Grid item key={property._id || property.userId}>
            <PropertyCard>
              <CardMedia
                component="img"
                height="200"
                image={
                  property.imageUrl || "https://via.placeholder.com/400x200"
                }
                alt={property.title}
              />
              <CardContent>
                <Typography variant="h6" component="div">
                  {property.title}
                </Typography>
                <Typography variant="body2" color="text.secondary" gutterBottom>
                  {property.location}
                </Typography>
                <Typography variant="body1" color="text.primary">
                  ${property.price} per sq ft
                </Typography>
                <Typography variant="body2" color="text.secondary" mb={2}>
                  {property.description}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Features: {property.features.join(", ")}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Total Area: {property.totalSquareFootage} sq ft
                </Typography>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                  fullWidth
                >
                  Mint Property
                </Button>
              </CardContent>
            </PropertyCard>
          </Grid>
        ))}
      </Grid>
    </StyledContainer>
  ) : (
    <Card
      sx={{ display: "flex", width: "100%", borderRadius: 2, boxShadow: 3 }}
    >
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1 }}>
        <CardContent>
          <Typography variant="h5" component="div" gutterBottom>
            No Properties Listed
          </Typography>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/add-property"
          >
            Add Property
          </Button>
        </CardContent>
      </Box>
    </Card>
  );
};

export default PropertyListPage;
