import React, { useEffect, useState } from "react";
import axios from "axios";
import PrintableTitle from "./PrintableTitle";
import { useParams } from "react-router-dom";
import { CircularProgress, Box, Typography } from "@mui/material";

const TitlePage = () => {
  const { titleID } = useParams(); // Assume we're getting the title ID from the URL
  const [titleData, setTitleData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTitle = async () => {
      try {
        const response = await axios.get(`/api/titles/title/${titleID}`);
        setTitleData(response.data);
        setLoading(false);
      } catch (error) {
        console.error("Error fetching title:", error);
      }
    };
    fetchTitle();
  }, [titleID]);
  console.log("titile ", titleData);
  if (loading) {
    return (
      <Box sx={{ display: "flex", justifyContent: "center", mt: 5 }}>
        <CircularProgress />
      </Box>
    );
  }

  return titleData ? (
    <PrintableTitle titleData={titleData} />
  ) : (
    <Typography variant="h6" color="error">
      Title not found
    </Typography>
  );
};

export default TitlePage;
