import React, { useContext } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  Button,
  Stack,
} from "@mui/material";
import { AuthContext } from "../../../../firebase/AuthContext";
import { Link } from "react-router-dom";

const AdminDashboard = () => {
  const { user } = useContext(AuthContext);

  return (
    <Card sx={{ maxWidth: "100vw", p: 2, boxShadow: 3 }}>
      <CardContent>
        <Typography variant="h5" component="div" gutterBottom>
          Admin Dashboard
        </Typography>
        <Typography variant="body2" color="text.secondary" gutterBottom>
          Welcome, {user.firstName} Manage your application from here.
        </Typography>

        <Stack direction="row" spacing={2} mt={2} justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/add-property"
          >
            Add Property
          </Button>

          <Button
            variant="contained"
            color="primary"
            component={Link}
            to="/investor-dashboard"
          >
            Investor Dashboard
          </Button>
        </Stack>
      </CardContent>
    </Card>
  );
};

export default AdminDashboard;
