import React from "react";
import { useParams } from "react-router-dom";
import PropertyMessaging from "./PropertyMessaging";
import GoBackButton from "../goBackButton";

const PropertyChatPage = () => {
  const { propertyId, ownerId, userId, parcelId } = useParams();

  return (
    <div>
      <GoBackButton />
      <h2>Property Chat</h2>
      <PropertyMessaging
        propertyId={propertyId}
        userId={userId}
        ownerId={ownerId}
        parcelId={parcelId}
      />
    </div>
  );
};

export default PropertyChatPage;
