import React, { useEffect, useState, useContext } from "react";
import { useParams, Link } from "react-router-dom";
import {
  Container,
  Typography,
  Button,
  Grid,
  Box,
  Divider,
  TextField,
  Alert,
} from "@mui/material";
import { Helmet } from "react-helmet-async";
import propertyApi from "../../api/property";
import PurchaseComponent from "../../components/purchase/PurchaseComponent";
import { AuthContext } from "../../firebase/AuthContext";
import ParcelViewer from "./parcelviewer";
import Presale from "./../presale";
const DetallePropiedad = () => {
  const { user } = useContext(AuthContext);
  const { id } = useParams();

  const [propiedad, setPropiedad] = useState(null);
  const [cargando, setCargando] = useState(true);
  const [error, setError] = useState(null);
  const [montoCompra, setMontoCompra] = useState(1);
  const [precioTotal, setPrecioTotal] = useState(0);

  useEffect(() => {
    if (!id) {
      setError("ID de propiedad no válido.");
      setCargando(false);
      return;
    }
    if (montoCompra > 1) {
      alert("Solo puedes reservar una propiedad a la vez");
    }
    const obtenerPropiedad = async () => {
      try {
        const response = await propertyApi.getById(id);
        setPropiedad(response.data);
        setPrecioTotal(response.data.presalePrice || 1500); // Default presale price
      } catch (err) {
        console.error("Error al obtener los detalles de la propiedad:", err);
        setError("No se pudo cargar la propiedad.");
      } finally {
        setCargando(false);
      }
    };

    obtenerPropiedad();
  }, [id, montoCompra]);

  const handleMontoCompraChange = (event) => {
    const value = event.target.value === "" ? "" : Number(event.target.value);
    const maxAmount = propiedad?.availableSquareFeet || 0;

    const nuevoMonto = value > maxAmount ? maxAmount : value;

    setMontoCompra(nuevoMonto);
    setPrecioTotal(nuevoMonto * (propiedad?.presalePrice || 1500)); // Calculate total based on presale price
  };

  if (cargando) {
    return (
      <Container maxWidth="md" sx={{ py: 4, textAlign: "center" }}>
        <Typography variant="h4">
          Cargando detalles de la propiedad...
        </Typography>
      </Container>
    );
  }

  if (error || !propiedad) {
    return (
      <Container maxWidth="md" sx={{ py: 4, textAlign: "center" }}>
        <Typography variant="h4">
          {error || "Propiedad no encontrada"}
        </Typography>
        <Button
          component={Link}
          to="/properties"
          variant="contained"
          sx={{ mt: 2 }}
        >
          Volver a las Propiedades
        </Button>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Helmet>
        <title>{propiedad.title}</title>
        <meta property="og:title" content={propiedad.title} />
        <meta property="og:description" content={propiedad.description} />
        <meta property="og:image" content={propiedad.imageUrl} />
      </Helmet>

      <Button
        component={Link}
        to="/properties"
        variant="outlined"
        sx={{ mb: 4, textTransform: "uppercase", fontWeight: "bold" }}
      >
        Volver a las Propiedades
      </Button>

      <Grid container spacing={4}>
        {/* Left Section */}
        <Grid item xs={12} md={6}>
          <ParcelViewer />
        </Grid>

        {/* Right Section */}
        <Grid item xs={12} md={6}>
          <Box>
            <Typography variant="h3" gutterBottom>
              {propiedad.title}
            </Typography>
            <Typography variant="body1" sx={{ mb: 2, color: "text.secondary" }}>
              {propiedad.description}
            </Typography>
            <Divider sx={{ my: 2 }} />
            <Typography variant="h6" sx={{ mb: 1 }}>
              Precios desde: ${propiedad.price || 1000} Por Lote
            </Typography>
            <Typography variant="body2" sx={{ mb: 3 }}>
              Ubicación: {propiedad.location}
            </Typography>
            <Typography variant="body2" sx={{ mb: 3 }}>
              Parcelas Disponibles: {propiedad.availableSquareFeet}
            </Typography>
          </Box>

          {propiedad.availableSquareFeet === 0 ? (
            <Typography
              color="red"
              variant="h6"
              sx={{ mt: 2, fontWeight: "bold" }}
            >
              Sold Out
            </Typography>
          ) : // <PurchaseComponent
          //   userFirstName={user?.firstName || ""}
          //   userLastName={user?.lastName || ""}
          //   userId={user?._id || ""}
          //   description={propiedad.description}
          //   property={propiedad}
          //   propertyId={propiedad._id}
          //   amount={montoCompra}
          //   price={precioTotal}
          //   priceUnit={"squareFoot"}
          //   option={"presale"}
          // />
          null}
        </Grid>
      </Grid>
    </Container>
  );
};

export default DetallePropiedad;
