import React, { useState, useEffect } from "react";
import property from "./../../../api/property";
import {
  Container,
  Grid,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";

const FetchProperties = () => {
  const [properties, setProperties] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchProperties = async () => {
      try {
        const response = await property.getAll();
        console.log("prope", response.data);
        setProperties(response.data);
      } catch (error) {
        setError("Failed to load properties");
        console.error(error);
      } finally {
        setLoading(false);
      }
    };

    fetchProperties();
  }, []);

  if (loading) {
    return (
      <Container style={{ textAlign: "center", marginTop: "50px" }}>
        <CircularProgress />
        <Typography variant="h6" style={{ marginTop: "10px" }}>
          Loading properties...
        </Typography>
      </Container>
    );
  }

  if (error) {
    return (
      <Container style={{ textAlign: "center", marginTop: "50px" }}>
        <Typography variant="h6" color="error">
          {error}
        </Typography>
      </Container>
    );
  }

  return (
    <Container style={{ marginTop: "30px" }}>
      <Typography
        variant="h4"
        style={{ textAlign: "center", marginBottom: "30px" }}
      >
        Propiedades
      </Typography>
      {properties.length === 0 ? (
        <Typography variant="h6" style={{ textAlign: "center" }}>
          No properties found.
        </Typography>
      ) : (
        <Grid container spacing={4}>
          {properties.map((property) => (
            <Grid item xs={12} sm={6} md={4} key={property._id}>
              <Card elevation={3} style={{ borderRadius: "15px" }}>
                <CardContent>
                  <Typography variant="h6" gutterBottom>
                    {property.name}
                  </Typography>
                  <Typography
                    style={{ padding: 10 }}
                    variant="body2"
                    color="textSecondary"
                  >
                    Location: {property.location}
                  </Typography>
                  <Button
                    component={Link}
                    to={`/parcels/${property._id}/${property.coordinates.Longitude}/${property.coordinates.Latitude}`}
                    variant="contained"
                    style={{ width: "100%" }}
                  >
                    Add Parcel
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
    </Container>
  );
};

export default FetchProperties;
