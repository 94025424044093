import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import mapboxgl from "mapbox-gl";
import {
  Box,
  CircularProgress,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import propertyApi from "../../../api/property";
import GoBackButton from "../../../components/goBackButton";
import { AuthContext } from "../../../firebase/AuthContext";
import PurchaseComponent from "../../../components/purchase/PurchaseComponent";
import PropertyChatPage from "../../../components/PropertyMessaging/propertyChatPage";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const ParcelViewer = () => {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const { id } = useParams(); // Get propertyId from URL params
  const [property, setProperty] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedParcel, setSelectedParcel] = useState(null); // Track selected parcel
  const mapContainer = useRef(null); // Reference to the map container
  const map = useRef(null); // Reference to the map instance

  useEffect(() => {
    const fetchProperty = async () => {
      try {
        const response = await propertyApi.getParcelById(id);
        console.log("Property data:", response.data);
        setProperty(response.data);
      } catch (err) {
        console.error(err);
        setError("Failed to fetch property.");
      } finally {
        setLoading(false);
      }
    };

    fetchProperty();
  }, [id]);

  useEffect(() => {
    if (loading || !Array.isArray(property.parcels)) return;

    // Initialize the map
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/satellite-v9",
      center: [property.coordinates.Longitude, property.coordinates.Latitude], // Default center
      zoom: 14,
    });

    // Wait for the map to load the style
    map.current.on("style.load", () => {
      property.parcels.forEach((parcel) => {
        if (parcel.coordinates && parcel.coordinates.length > 0) {
          // Add GeoJSON source for the parcel
          map.current.addSource(`parcel-${parcel._id}`, {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: {
                type: "Polygon",
                coordinates: [parcel.coordinates], // Ensure this matches your GeoJSON format
              },
              properties: {
                id: parcel._id,
                estado: parcel.estado,
                parcelNumber: parcel.parcelNumber,
                owner: parcel.owner,
                price: parcel.price,
              },
            },
          });

          // Add a fill layer for the parcel
          map.current.addLayer({
            id: `parcel-layer-${parcel._id}`,
            type: "fill",
            source: `parcel-${parcel._id}`,
            paint: {
              "fill-color": getParcelColor(parcel.estado, parcel.owner),
              "fill-opacity": 0.5,
            },
          });

          // Add a border layer for the parcel
          map.current.addLayer({
            id: `parcel-border-${parcel._id}`,
            type: "line",
            source: `parcel-${parcel._id}`,
            paint: {
              "line-color": "black",
              "line-width": 2,
            },
          });

          // Add a symbol layer to display parcel numbers
          map.current.addLayer({
            id: `parcel-number-${parcel._id}`,
            type: "symbol",
            source: `parcel-${parcel._id}`,
            layout: {
              "text-field": `#${parcel.parcelNumber}`, // Display parcel number
              "text-font": ["Open Sans Bold", "Arial Unicode MS Bold"],
              "text-size": 10,
              "text-offset": [0, 0.5], // Offset to center text above the polygon
              "text-anchor": "top",
            },
            paint: {
              "text-color": "black",
            },
          });
        }
      });

      // Add a click event listener to handle parcel clicks
      map.current.on("click", (e) => {
        const features = map.current.queryRenderedFeatures(e.point, {
          layers: property.parcels.map(
            (parcel) => `parcel-layer-${parcel._id}`
          ),
        });

        if (features.length > 0) {
          const clickedParcel = features[0].properties;
          const parcelDetails = property.parcels.find(
            (p) => p._id === clickedParcel.id
          );
          setSelectedParcel(parcelDetails);
        }
      });

      // Change cursor to pointer when hovering over property
      map.current.on("mouseenter", (e) => {
        const features = map.current.queryRenderedFeatures(e.point, {
          layers: property.parcels.map(
            (parcel) => `parcel-layer-${parcel._id}`
          ),
        });
        if (features.length > 0) {
          map.current.getCanvas().style.cursor = "pointer";
        }
      });

      map.current.on("mouseleave", () => {
        map.current.getCanvas().style.cursor = "";
      });
    });

    return () => {
      // Clean up the map instance
      if (map.current) {
        map.current.remove();
      }
    };
  }, [property, loading]);

  const getParcelColor = (estado, id) => {
    switch (estado) {
      case "vendida":
        return id === user._id ? "blue" : "red";
      case "en venta":
        return id && id === user._id ? "green" : "limegreen";
      case "reserved":
        return "yellow";
      default:
        return "gray";
    }
  };

  // const handlePurchase = () => {
  //   if (selectedParcel) {
  //     navigate(`/purchase/${selectedParcel.parcelNumber}`, {
  //       state: { parcel: selectedParcel },
  //     });
  //   }
  // };

  if (loading) {
    return (
      <Box textAlign="center" mt={4}>
        <CircularProgress />
        <Typography mt={2}>Loading property...</Typography>
      </Box>
    );
  }

  if (error) {
    return (
      <Box textAlign="center" mt={4}>
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Box mt={4}>
      <Box
        ref={mapContainer}
        style={{ width: "100%", height: "600px", borderRadius: "10px" }}
      />
      {selectedParcel && (
        <Dialog
          style={{
            width: "100%",
            height: "600px",
            borderRadius: "50px",
            paddin: "100px",
          }}
          open={Boolean(selectedParcel)}
          onClose={() => setSelectedParcel(null)}
        >
          <DialogTitle>Detalles</DialogTitle>
          <DialogContent>
            <Typography>Parcela #: {selectedParcel.parcelNumber}</Typography>
            <Typography>
              Area: {selectedParcel.area} metros Cuadrados
            </Typography>
            <Typography>Precio: ${selectedParcel.price.toFixed(2)}</Typography>
            <Typography>Estado: {selectedParcel.estado}</Typography>
            {selectedParcel.owner ? (
              <Typography>
                {selectedParcel.owner === user._id ? "Mi property" : null}
              </Typography>
            ) : null}
          </DialogContent>

          <DialogActions>
            {selectedParcel.owner === user._id ? (
              <Button
                style={{ width: "100%" }}
                component={Link}
                to={`/messages/${selectedParcel._id}/${user._id}/${selectedParcel.owner}`}
              >
                ver mensaje
              </Button>
            ) : (
              <Button
                style={{ width: "100%" }}
                component={Link}
                to={`/messages/${selectedParcel._id}/${user._id}/${
                  selectedParcel.owner ? selectedParcel.owner : property.userId
                }/${property._id}`}
              >
                Enviar Mensaje
              </Button>
            )}
          </DialogActions>
          <DialogActions>
            <Button
              style={{ width: "100%" }}
              onClick={() => setSelectedParcel(null)}
            >
              X
            </Button>
          </DialogActions>
        </Dialog>
      )}
    </Box>
  );
};

export default ParcelViewer;
