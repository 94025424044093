import React, { useEffect, useState, useRef } from "react";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Container,
  CircularProgress,
  Divider,
  Button,
  TextField,
} from "@mui/material";
import axios from "axios";
import { useParams } from "react-router-dom";
import GoBackButton from "../../../components/goBackButton";
import logo from "./../../../assets/images/logo/logo.png";
import mapboxgl from "mapbox-gl";

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const AggregatedTitle = () => {
  const { userId } = useParams();
  const [title, setTitle] = useState(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [loading, setLoading] = useState(true);
  const [sellingPrices, setSellingPrices] = useState({}); // Track prices for each parcel
  const mapContainerRefs = useRef([]); // References for map containers

  useEffect(() => {
    const fetchAggregatedTitle = async () => {
      try {
        const response = await axios.get(`/api/titles/user/${userId}`);
        console.log("responskkkk", response.data);
        setTitle(response.data);
      } catch (error) {
        console.error("Error fetching aggregated title:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchAggregatedTitle();
  }, [userId]);

  const transformCoordinates = (flatCoordinates) => {
    if (!Array.isArray(flatCoordinates) || flatCoordinates.length % 2 !== 0) {
      console.error("Invalid flat coordinates:", flatCoordinates);
      return [];
    }
    const coordinates = [];
    for (let i = 0; i < flatCoordinates.length; i += 2) {
      coordinates.push([flatCoordinates[i], flatCoordinates[i + 1]]);
    }
    return coordinates;
  };

  const handleSellingPriceChange = (parcelId, value) => {
    setSellingPrices((prevPrices) => ({
      ...prevPrices,
      [parcelId]: value,
    }));
  };

  const handleMarkForSale = async (parcelId, estado) => {
    const price = sellingPrices[parcelId];
    if (!price || isNaN(price) || price <= 0) {
      setAlertMessage("Please add a Valid number");
      return;
    }

    try {
      const response = await axios.post(`/api/parcels/sale`, {
        parcelId,
        price,
        estado,
      });
      setAlertMessage("Parcel status updated successfully!");
      setTitle((prevTitle) => {
        const updatedParcels = prevTitle.parcels.map((parcel) =>
          parcel.parcelId === parcelId ? { ...parcel, estado, price } : parcel
        );
        return { ...prevTitle, parcels: updatedParcels };
      });
    } catch (error) {
      console.error("Error marking parcel for sale:", error);
      alert("Failed to update parcel status.");
    }
  };

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "50vh",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (!title) {
    return (
      <Container maxWidth="lg" sx={{ py: 6 }}>
        <GoBackButton />
        <Typography
          variant="h5"
          align="center"
          color="text.secondary"
          sx={{ marginTop: 4 }}
        >
          No title found.
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: 6 }}>
      <GoBackButton />
      <Card
        sx={{
          borderRadius: "16px",
          overflow: "hidden",
          position: "relative",
          boxShadow: "0px 8px 25px rgba(0, 0, 0, 0.2)",
          background:
            "linear-gradient(145deg, #ffffff, #f4f4f4, #eaeaea, #d9d9d9)",
          border: "1px solid #ccc",
        }}
      >
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundImage: `url(${logo})`,
            backgroundSize: "20%",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat",
            opacity: 0.05,
            zIndex: 0,
          }}
        />

        <CardContent
          sx={{
            position: "relative",
            zIndex: 1,
            padding: 4,
            display: "flex",
            flexDirection: "column",
            gap: 2,
          }}
        >
          <Typography
            variant="h5"
            sx={{
              fontWeight: 700,
              textTransform: "uppercase",
              color: "primary",
            }}
          >
            Propiedades
          </Typography>

          <Divider sx={{ my: 2 }} />

          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            <strong>Owner:</strong> {title.ownerFirstName} {title.ownerLastName}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            <strong>Total Parcels:</strong> {title.totalAmount}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            <strong>Property Name:</strong>{" "}
            {title.property?.title || "Property title unavailable"}
          </Typography>
          <Typography variant="body1" sx={{ fontWeight: 600 }}>
            <strong>Location:</strong>{" "}
            {title.property?.location || "Location unavailable"}
          </Typography>
          <Divider sx={{ my: 2 }} />

          <Typography variant="body1" sx={{ fontWeight: 600, color: "green" }}>
            <strong>{alertMessage}</strong>
          </Typography>
          {title.parcels.length > 0 ? (
            title.parcels.map((parcel) => {
              const geoJsonCoordinates = transformCoordinates(
                parcel.coordinates
              );
              return (
                <Box
                  key={parcel.parcelId}
                  sx={{
                    mb: 2,
                    p: 2,
                    backgroundColor:
                      parcel.estado === "en venta" ? "limegreen" : "black",
                    borderRadius: "8px",
                    boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
                  }}
                >
                  <Typography variant="body1" sx={{ fontWeight: 600 }}>
                    <strong>Parcel Number:</strong> {parcel.parcelNumber}
                  </Typography>
                  <Box
                    sx={{
                      width: "100%",
                      height: "200px",
                      borderRadius: "8px",
                      mt: 2,
                    }}
                    ref={(el) => {
                      if (el && geoJsonCoordinates.length > 0) {
                        const map = new mapboxgl.Map({
                          container: el,
                          style: "mapbox://styles/mapbox/satellite-v9",
                          center: geoJsonCoordinates[0],
                          zoom: 15,
                        });

                        map.on("load", () => {
                          map.addSource(`parcel-${parcel.parcelId}`, {
                            type: "geojson",
                            data: {
                              type: "Feature",
                              geometry: {
                                type: "Polygon",
                                coordinates: [geoJsonCoordinates],
                              },
                            },
                          });

                          map.addLayer({
                            id: `parcel-layer-${parcel.parcelId}`,
                            type: "fill",
                            source: `parcel-${parcel.parcelId}`,
                            paint: {
                              "fill-color": "rgba(0, 200, 0, 0.5)",
                              "fill-outline-color": "#000",
                            },
                          });
                        });
                      }
                    }}
                  />
                  <Box sx={{ mt: 2 }}>
                    <TextField
                      label="Set Price"
                      type="number"
                      value={sellingPrices[parcel.parcelId] || ""}
                      onChange={(e) =>
                        handleSellingPriceChange(
                          parcel.parcelId,
                          e.target.value
                        )
                      }
                      sx={{ mr: 2, width: "150px" }}
                    />
                    <Button
                      variant="outlined"
                      fullWidth
                      color="grey"
                      onClick={() =>
                        handleMarkForSale(
                          parcel.parcelId,
                          parcel.estado === "vendida" ? "en venta" : "vendida"
                        )
                      }
                    >
                      {parcel.estado === "vendida" ? "Sell" : "Unlist"}
                    </Button>
                  </Box>
                </Box>
              );
            })
          ) : (
            <Typography variant="body2" color="text.secondary">
              No parcels associated with this title.
            </Typography>
          )}
        </CardContent>
      </Card>
    </Container>
  );
};

export default AggregatedTitle;
