import axios from "axios";

export default {
  create: function (data) {
    console.log("axios data now create", data);
    return axios.post("/api/users/", data);
  },
  findByEmail: function (email) {
    return axios.get("/api/users/email/" + email);
  },
  getAll: function () {
    return axios.get("/api/users");
  },
  loginByEmail: function (email, password) {
    console.log("axios data now create email ", email, password);
    return axios.get("/api/users/email/" + email + "/password/" + password);
  },
};
