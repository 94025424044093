import React from "react";
import {
  Box,
  Typography,
  Container,
  Grid,
  Button,
  IconButton,
  Link,
} from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";

const Footer = () => {
  return (
    <Box
      sx={{
        width: "100%",
        backgroundColor: "#000",
        color: "white",
        paddingY: { xs: 4, sm: 6 },
        mt: 4,
      }}
    >
      <Container maxWidth="xl">
        <Grid container spacing={2} justifyContent="space-between">
          {/* Brand and Tagline */}
          <Grid item xs={12} sm={4} textAlign={{ xs: "center", sm: "left" }}>
            <Typography variant="h6" fontWeight="700">
              ParcelMint
            </Typography>
            <Typography variant="body2" color="text.secondary">
              Redefining real estate ownership.
            </Typography>
          </Grid>

          {/* Social Icons */}
          <Grid
            item
            xs={12}
            sm={4}
            textAlign={{ xs: "center", sm: "right" }}
            sx={{
              display: "flex",
              justifyContent: { xs: "center", sm: "flex-end" },
              gap: 1,
            }}
          >
            <IconButton sx={{ color: "white" }}>
              <FacebookIcon />
            </IconButton>
            <IconButton sx={{ color: "white" }}>
              <TwitterIcon />
            </IconButton>
          </Grid>
        </Grid>

        {/* Footer Bottom Line */}
        <Box
          sx={{
            marginTop: { xs: 4, sm: 6 },
            borderTop: "1px solid #333",
            paddingTop: 2,
            textAlign: "center",
          }}
        >
          <Typography variant="body2" color="text.secondary">
            © {new Date().getFullYear()} ParcelMint. All rights reserved.{" "}
            <Link
              href="/terms-and-conditions"
              sx={{
                color: "white",
                textDecoration: "underline",
                marginLeft: "0.5rem",
                "&:hover": {
                  textDecoration: "none",
                  color: "primary.main",
                },
              }}
            >
              Terms and Conditions
            </Link>
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
