import React, { useState, useContext, useEffect } from "react";
import { auth } from "./../firebase/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";

import { Link } from "react-router-dom";
import { AuthContext } from "./../firebase/AuthContext";
import { useNavigate } from "react-router-dom";
import userApi from "./../api/user";
import { Box, Button, TextField } from "@mui/material";
function Login() {
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  console.log(
    "Firebase API Key: login ",
    process.env.REACT_APP_FIREBASE_API_KEY
  );

  useEffect(() => {
    if (user) {
      // If the user is already logged in, redirect to home or dashboard
      navigate("/profile");
    }
  }, [user, navigate]);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");

  const handleLogin = (e) => {
    e.preventDefault();
    try {
      userApi
        .loginByEmail(email, password)
        .then((result) => {
          console.log("client api ", result);
          signInWithEmailAndPassword(auth, email, password)
            .then((re) => {
              console.log("lofing firebase ", re);
            })
            .catch((er) => {
              console.log("errrr ", er);
            });
        })
        .catch((err) => {
          console.log("err client api ", err);
        });
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <div
      style={{
        width: "100%",

        textAlign: "center",
      }}
    >
      <h2>Sign Up</h2>
      <Box
        component="form"
        sx={{ "& > :not(style)": { m: 1, width: "25ch" } }}
        noValidate
        autoComplete="off"
      >
        <br></br>
        <TextField
          type="email"
          onChange={(e) => setEmail(e.target.value)}
          label="Email"
          variant="outlined"
        />
        <br></br>
        <TextField
          type="password"
          onChange={(e) => setPassword(e.target.value)}
          label="Password"
          variant="outlined"
        />
      </Box>
      <Button
        onClick={(e) => handleLogin(e)}
        color="primary"
        variant="contained"
      >
        Login
      </Button>
      <div>
        {" "}
        <Link to={"/signup"}>SignUp</Link>
      </div>
    </div>
  );
}

export default Login;
