import React from "react";
import {
  Box,
  Container,
  Typography,
  Card,
  CardContent,
  Grid,
} from "@mui/material";

const InvestorDashboard = () => {
  // Placeholder data
  const investmentDetails = {
    amount: "$1,000",
    profitSharePercentage: "5%",
    totalEarned: "$200",
    growthProgress: "10%",
  };

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" gutterBottom textAlign="center">
        Welcome to Your Investor Dashboard
      </Typography>
      <Typography
        variant="subtitle1"
        color="text.secondary"
        textAlign="center"
        paragraph
      >
        Track your investment, see ParcelMint’s progress, and view your
        earnings.
      </Typography>

      {/* Investment Summary */}
      <Card elevation={3} sx={{ mt: 4 }}>
        <CardContent>
          <Typography variant="h6">Investment Summary</Typography>
          <Typography variant="body1">
            Amount Invested: {investmentDetails.amount}
          </Typography>
          <Typography variant="body1">
            Profit Share: {investmentDetails.profitSharePercentage}
          </Typography>
          <Typography variant="body1">
            Total Earnings: {investmentDetails.totalEarned}
          </Typography>
          <Typography variant="body1">
            Growth Progress: {investmentDetails.growthProgress}
          </Typography>
        </CardContent>
      </Card>

      {/* Progress and Updates */}
      <Box sx={{ mt: 6 }}>
        <Typography variant="h5" gutterBottom>
          Latest Updates
        </Typography>
        <Typography variant="body1" paragraph>
          We’re currently developing new features and onboarding more
          properties. Stay tuned for upcoming milestones!
        </Typography>
      </Box>
    </Container>
  );
};

export default InvestorDashboard;
