import React from "react";
import { Box, Typography, Button, Paper } from "@mui/material";
import { useNavigate } from "react-router-dom";

const PrintableTitle = ({ titleData }) => {
  const navigate = useNavigate();

  const handlePrint = () => {
    window.print();
  };

  return (
    <Box sx={{ padding: 4, backgroundColor: "#f9f9f9", minHeight: "100vh" }}>
      {/* Back Button */}
      <Button
        variant="outlined"
        onClick={() => navigate(-1)}
        sx={{
          marginBottom: 3,
          borderRadius: "20px",
          textTransform: "none",
        }}
      >
        Go Back
      </Button>

      {/* Title Certificate */}
      <Box sx={{ display: "flex", justifyContent: "center" }}>
        <Paper
          elevation={4}
          sx={{
            padding: 5,
            width: "100%",
            maxWidth: "800px",
            textAlign: "center",
            borderRadius: "15px",
            backgroundColor: "#ffffff",
          }}
        >
          {/* Header Section */}
          <Box
            sx={{
              backgroundColor: "primary.main",
              color: "white",
              padding: 3,
              borderRadius: "15px 15px 0 0",
              marginBottom: 4,
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: 700,
                fontSize: { xs: "1.8rem", sm: "2.2rem" },
              }}
            >
              Digital Title Certificate
            </Typography>
            <Typography
              variant="body1"
              sx={{
                marginTop: 1,
                fontSize: { xs: "0.9rem", sm: "1rem" },
              }}
            >
              Official Ownership Document
            </Typography>
          </Box>

          {/* Title Details */}
          <Typography
            variant="h6"
            gutterBottom
            sx={{ fontWeight: 600, color: "text.primary", marginBottom: 2 }}
          >
            Title ID: {titleData.titleID}
          </Typography>
          <Box
            sx={{
              marginBottom: 3,
              padding: 3,
              border: "1px solid #e0e0e0",
              borderRadius: "10px",
            }}
          >
            <Typography variant="body1" gutterBottom>
              <strong>Owner:</strong> {titleData.ownerFirstName}{" "}
              {titleData.ownerLastName}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>Parcel #:</strong> {titleData.parcelNumber}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>Property ID:</strong> {titleData.propertyID}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>Owner ID:</strong> {titleData.ownerID}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>Square Footage:</strong> {titleData.amount} sq ft
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>Purchase Date:</strong>{" "}
              {new Date(titleData.purchaseDate).toLocaleDateString()}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>Plot Details:</strong> {titleData.plotDetails}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>Resale Status:</strong> {titleData.resaleStatus}
            </Typography>
            <Typography variant="body1" gutterBottom>
              <strong>Holding Period:</strong> {titleData.holdingPeriod} days
            </Typography>

            <Typography variant="body1" gutterBottom>
              <strong>Features:</strong> {titleData.fractionFeatures}
            </Typography>
          </Box>

          {/* Print Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={handlePrint}
            sx={{
              borderRadius: "20px",
              textTransform: "none",
              paddingX: 4,
            }}
          >
            Print Title
          </Button>
        </Paper>
      </Box>
    </Box>
  );
};

export default PrintableTitle;
