// ExampleComponent.js
import React from "react";
import { useTranslation } from "react-i18next";
import LanguageSwitcher from "./../components/LanguageSwitcher/index";
const ExampleComponent = () => {
  const { t } = useTranslation();

  return (
    <div>
      <LanguageSwitcher />
      <h1>{t("welcomeMessage")}</h1>
      <p>{t("propertyDescription")}</p>
      <p>{t("price")}</p>
    </div>
  );
};

export default ExampleComponent;
