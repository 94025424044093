import React, { useContext, useEffect, useState } from "react";
import { AuthContext } from "../../firebase/AuthContext";
import { Button, Box, Typography, Grid } from "@mui/material";
import { Link } from "react-router-dom";

const Dashboard = () => {
  const { user } = useContext(AuthContext);
  const [roleContent, setRoleContent] = useState(null);

  useEffect(() => {
    if (user) {
      switch (user.role) {
        case "Buyer":
          setRoleContent(
            <>
              <Typography
                variant="h4"
                align="center"
                gutterBottom
                sx={{ fontWeight: "bold", mb: 4 }}
              ></Typography>
              <Grid container spacing={4} justifyContent="center">
                <Grid item xs={12} sm={6} md={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/teams"
                    sx={{ py: 2, borderRadius: "20px", fontSize: "1rem" }}
                  >
                    Ver Propiedades
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/leagues"
                    sx={{ py: 2, borderRadius: "20px", fontSize: "1rem" }}
                  >
                    Buscar
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6} md={4}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    component={Link}
                    to={`/business-name/${user._id}`}
                    sx={{ py: 2, borderRadius: "20px", fontSize: "1rem" }}
                  >
                    Perfil de Negocio
                  </Button>
                </Grid>
              </Grid>
            </>
          );
          break;
        case "Seller":
          setRoleContent(
            <>
              <Typography
                variant="h4"
                align="center"
                gutterBottom
                sx={{ fontWeight: "bold", mb: 4 }}
              >
                Panel del Vendedor
              </Typography>
              <Grid container spacing={4} justifyContent="center">
                <Grid item xs={12} sm={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/player-profile"
                    sx={{ py: 2, borderRadius: "20px", fontSize: "1rem" }}
                  >
                    Vender Nueva Propiedad
                  </Button>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    component={Link}
                    to="/player-matches"
                    sx={{ py: 2, borderRadius: "20px", fontSize: "1rem" }}
                  >
                    Ver Parcelas
                  </Button>
                </Grid>
              </Grid>
            </>
          );
          break;
        default:
          setRoleContent(
            <Typography
              variant="body1"
              align="center"
              sx={{ mt: 4, fontSize: "1.2rem" }}
            >
              Rol no reconocido.
            </Typography>
          );
      }
    }
  }, [user]);

  return (
    <Box
      sx={{
        p: 3,
        textAlign: "center",
        maxWidth: "800px",
        mx: "auto",
        backgroundColor: "#f9f9f9",
        borderRadius: "15px",
        boxShadow: "0 4px 15px rgba(0, 0, 0, 0.1)",
      }}
    >
      {roleContent}
    </Box>
  );
};

export default Dashboard;
