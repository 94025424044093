// src/i18n.js
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import en from "./locales/en/translation.json";
import es from "./locales/es/translation.json";

// Initialize i18next
i18n.use(initReactI18next).init({
  resources: {
    en: { translation: en },
    es: { translation: es },
  },
  lng: "en", // default language
  fallbackLng: "en", // fallback language if the selected language is unavailable
  interpolation: {
    escapeValue: false, // React already does escaping
  },
});

export default i18n;
