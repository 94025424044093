import React, { useRef, useEffect, useState } from "react";
import mapboxgl from "mapbox-gl";
import MapboxDraw from "@mapbox/mapbox-gl-draw";
import * as turf from "@turf/turf";
import { useParams } from "react-router-dom"; // Import useParams
mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

const InteractiveParcelEditor = () => {
  const { propertyId, Latitude, Longitude } = useParams(); // Get propertyId from route params
  const mapContainer = useRef(null);
  const map = useRef(null);
  const draw = useRef(null); // Mapbox Draw instance
  const [propertyBoundary, setPropertyBoundary] = useState([]);
  const [isDrawingBoundary, setIsDrawingBoundary] = useState(false);
  const [parcelsX, setParcelsX] = useState(1);
  const [parcelsY, setParcelsY] = useState(5);
  const [generatedParcels, setGeneratedParcels] = useState([]);
  const [message, setMessage] = useState("");
  useEffect(() => {
    if (map.current) return;

    // Initialize Mapbox map
    map.current = new mapboxgl.Map({
      container: mapContainer.current,
      style: "mapbox://styles/mapbox/satellite-v9",
      center: [Latitude, Longitude], // Default center
      zoom: 17,
    });

    // Initialize Mapbox Draw
    draw.current = new MapboxDraw({
      displayControlsDefault: false,
      controls: {
        trash: true, // Enable deleting parcels
      },
    });

    map.current.addControl(draw.current);

    // Handle property boundary drawing
    map.current.on("click", (e) => {
      if (!isDrawingBoundary) return;
      const lngLat = [e.lngLat.lng, e.lngLat.lat];
      setPropertyBoundary((prev) => [...prev, lngLat]);
    });

    return () => {
      if (map.current) {
        map.current.remove();
        map.current = null;
      }
    };
  }, [isDrawingBoundary]);

  useEffect(() => {
    if (!map.current || propertyBoundary.length === 0) return;

    // Draw property boundary dynamically
    const boundaryFeature = {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [propertyBoundary.concat([propertyBoundary[0]])],
      },
    };

    if (map.current.getSource("boundary")) {
      map.current.getSource("boundary").setData(boundaryFeature);
    } else {
      map.current.addSource("boundary", {
        type: "geojson",
        data: boundaryFeature,
      });

      map.current.addLayer({
        id: "boundary",
        type: "line",
        source: "boundary",
        paint: {
          "line-color": "#ff0000",
          "line-width": 2,
        },
      });
    }
  }, [propertyBoundary]);

  const startDrawingBoundary = () => {
    setIsDrawingBoundary(true);
    setMessage(
      "Click on the map to define property boundary points. Click 'Stop Drawing' when done."
    );
  };

  const stopDrawingBoundary = () => {
    setIsDrawingBoundary(false);
    if (propertyBoundary.length < 3) {
      setMessage(
        "Boundary is incomplete. Please define at least three points."
      );
      setPropertyBoundary([]);
    } else {
      setMessage("Boundary drawing stopped.");
    }
  };

  const generateParcels = () => {
    if (propertyBoundary.length < 3) {
      setMessage("Please complete the property boundary first!");
      return;
    }

    const propertyPolygon = turf.polygon([
      propertyBoundary.concat([propertyBoundary[0]]),
    ]);

    // Get the bounding box of the property
    const bbox = turf.bbox(propertyPolygon);

    // Calculate grid dimensions
    const cellWidth = (bbox[2] - bbox[0]) / parcelsX;
    const cellHeight = (bbox[3] - bbox[1]) / parcelsY;

    // Generate a grid using the calculated dimensions
    const grid = turf.rectangleGrid(bbox, cellWidth, cellHeight, {
      units: "degrees",
    });

    const parcels = [];
    turf.featureEach(grid, (cell) => {
      if (turf.booleanIntersects(cell, propertyPolygon)) {
        parcels.push(cell);
      }
    });

    parcels.forEach((parcel, index) => {
      const id = `parcel #${index}`;
      draw.current.add({
        id,
        type: "Feature",
        geometry: parcel.geometry,
        properties: {
          id,
        },
      });
    });

    const parcelData = parcels.map((parcel, index) => ({
      id: `parcel-${index}`,
      coordinates: parcel.geometry.coordinates[0],
    }));

    setGeneratedParcels(parcelData);
    setMessage("Parcels generated! You can now move or resize them.");
  };

  const enableEditing = () => {
    draw.current.changeMode("simple_select"); // Enable simple select for moving parcels
    setMessage("Edit mode enabled. You can now move or resize parcels.");
  };

  const resetMap = () => {
    setPropertyBoundary([]);
    setGeneratedParcels([]);
    setIsDrawingBoundary(false);

    if (map.current.getSource("boundary")) {
      map.current.removeLayer("boundary");
      map.current.removeSource("boundary");
    }

    draw.current.deleteAll();
  };

  const saveParcels = async () => {
    const parcels = draw.current.getAll().features.map((feature) => ({
      id: feature.properties.id,
      coordinates: feature.geometry.coordinates[0],
    }));

    if (parcels.length === 0) {
      setMessage("No parcels to save. Please generate parcels first.");
      return;
    }

    try {
      const response = await fetch("/api/parcels", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ parcels, propertyId }),
      });

      if (response.ok) {
        setMessage("Parcels saved successfully!");
      } else {
        setMessage("Failed to save parcels to the database.");
      }
    } catch (error) {
      console.error("Error saving parcels:", error);
      setMessage("Failed to save parcels to the database.");
    }
  };

  return (
    <div style={{ padding: 10 }}>
      <p>{message}</p>
      <h1>Parcel Editor</h1>
      <div style={{ marginBottom: "10px" }}>
        <button onClick={startDrawingBoundary}>Start Drawing Boundary</button>
        <button onClick={stopDrawingBoundary}>Stop Drawing</button>
        <button onClick={generateParcels}>Generate Parcels</button>
        <button onClick={enableEditing}>Enable Editing</button>
        <button onClick={saveParcels}>Save Parcels</button>
        <button onClick={resetMap}>Reset</button>
      </div>
      <div style={{ marginBottom: "10px" }}>
        <label>
          Parcels (X-direction):
          <input
            type="number"
            value={parcelsX}
            onChange={(e) => setParcelsX(Number(e.target.value))}
          />
        </label>
        <label>
          Parcels (Y-direction):
          <input
            type="number"
            value={parcelsY}
            onChange={(e) => setParcelsY(Number(e.target.value))}
          />
        </label>
      </div>
      <div ref={mapContainer} style={{ width: "100%", height: "70vh" }} />
    </div>
  );
};

export default InteractiveParcelEditor;
