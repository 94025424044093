import React, { createContext, useState, useEffect } from "react";
import { getAuth, onAuthStateChanged, signOut } from "firebase/auth";
import axios from "axios";
import LoadingScreen from "./../components/LoadingContext/LoadingScreen";

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true); // Initially set loading to true
  const [error, setError] = useState(null);

  const auth = getAuth();

  useEffect(() => {
    console.log("Initializing AuthProvider...");

    const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {
      if (currentUser) {
        try {
          const token = await currentUser.getIdToken(); // Fetch Firebase JWT
          console.log("Firebase token:", token);

          const cachedUser = JSON.parse(localStorage.getItem("currentUser"));

          // Fetch user from MongoDB or use cached user
          const result = await axios.get(
            `/api/users/email/${currentUser.email}`,
            {
              headers: {
                Authorization: `Bearer ${token}`, // Pass Firebase JWT
              },
            }
          );
          console.log("-----------------lll ", result);
          const currentUserWithDB = {
            firstName: result.data.firstName,
            lastName: result.data.lastName,
            email: result.data.email,
            isAdmin: result.data.isAdmin,
            role: result.data.role,
            isInvestor: result.data.isInvestor,
            _id: result.data._id,
          };
          console.log("Fetched user from MongoDB:", currentUserWithDB);
          setUser(currentUserWithDB);
          localStorage.setItem(
            "currentUser",
            JSON.stringify(currentUserWithDB)
          );
          console.log("Fetched user from MongoDB:", currentUserWithDB);
          setUser(currentUserWithDB);

          // Store the user in localStorage
          localStorage.setItem(
            "currentUser",
            JSON.stringify(currentUserWithDB)
          );

          console.log("Fetched user:", currentUserWithDB);
          setUser(currentUserWithDB);

          // Cache the user in localStorage
          localStorage.setItem(
            "currentUser",
            JSON.stringify(currentUserWithDB)
          );
        } catch (err) {
          console.error("Error fetching user data:", err);
          setError("Failed to fetch user details.");
        }
      } else {
        console.log("User not authenticated.");
        setUser(null);
        localStorage.removeItem("currentUser");
      }

      setLoading(false);
    });

    return () => unsubscribe();
  }, [auth]);

  const getToken = async () => {
    const currentUser = auth.currentUser;
    if (currentUser) {
      return await currentUser.getIdToken();
    }
    return null;
  };

  const logout = async () => {
    await signOut(auth);
    setUser(null);
    localStorage.removeItem("currentUser");
  };

  return (
    <AuthContext.Provider value={{ user, loading, error, logout, getToken }}>
      {loading ? <LoadingScreen /> : children}
    </AuthContext.Provider>
  );
};
