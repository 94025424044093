import React, { useContext, useEffect, useState } from "react";
import {
  Box,
  Container,
  Typography,
  IconButton,
  Avatar,
  Paper,
  Grid,
  Button,
} from "@mui/material";
import { ThumbUp, Person } from "@mui/icons-material";
import axios from "axios";
import { AuthContext } from "./../firebase/AuthContext";
import { useParams } from "react-router-dom";
import Loading from "../components/Loading";
import GoBackButton from "../components/goBackButton";

const PublicBusinessPage = () => {
  const { businessId } = useParams();
  const [business, setBusiness] = useState(null);
  const [isLiked, setIsLiked] = useState(false);
  const [isFollowing, setIsFollowing] = useState(false);
  const [likesCount, setLikesCount] = useState(0);
  const [followersCount, setFollowersCount] = useState(0);

  const { user } = useContext(AuthContext);

  // Fetch initial data for the business
  // Fetch initial data for the business
  useEffect(() => {
    const config = user?.token
      ? { headers: { Authorization: `Bearer ${user.token}` } }
      : {}; // No headers if not authenticated

    axios
      .get(`/api/business/business/id/${businessId}`, config)
      .then((response) => {
        const data = response.data;
        setBusiness(data);
        setIsLiked(user && data.likes.includes(user._id));
        setIsFollowing(user && data.followers.includes(user._id));
        setLikesCount(data.likes.length);
        setFollowersCount(data.followers.length);
      })
      .catch((error) => {
        console.error("Error fetching sponsor profile:", error);
      });
  }, [businessId, user]);

  const handleLike = () => {
    const apiEndpoint = `/api/business/business/${businessId}/like`;

    axios
      .post(
        apiEndpoint,
        {}, // No body needed
        {
          headers: {
            Authorization: `Bearer ${user.token}`, // Pass the JWT token
          },
        }
      )
      .then(() => {
        setIsLiked(!isLiked);
        setLikesCount((prev) => (isLiked ? prev - 1 : prev + 1));
      })
      .catch((error) => {
        console.error("Error updating like status:", error);
      });
  };

  const handleFollow = () => {
    const apiEndpoint = `/api/business/business/${businessId}/follow`;

    axios
      .post(
        apiEndpoint,
        {}, // No body needed
        {
          headers: {
            Authorization: `Bearer ${user.token}`, // Pass the JWT token
          },
        }
      )
      .then(() => {
        setIsFollowing(!isFollowing);
        setFollowersCount((prev) => (isFollowing ? prev - 1 : prev + 1));
      })
      .catch((error) => {
        console.error("Error updating follow status:", error);
      });
  };

  if (!business) {
    return <Loading message={"Loading"} />;
  }

  return (
    <Container sx={{ mt: 5, maxWidth: "lg" }}>
      <GoBackButton />
      <Paper
        sx={{
          p: 4,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          borderRadius: 2,
          backgroundColor: "white",
          boxShadow: 13,
          color: "black",
        }}
      >
        <Avatar
          alt="Business Logo"
          src={business.logoUrl || null}
          sx={{ width: 150, height: 150, borderRadius: "50%" }}
        />
        <Typography variant="h3" sx={{ fontWeight: "bold", mt: 2 }}>
          {business.businessName}
        </Typography>
        <Typography variant="body1" color="textSecondary" sx={{ mb: 3 }}>
          {business.description}
        </Typography>

        {/* Social Stats: Likes and Followers */}
        <Box sx={{ display: "flex", alignItems: "center", mt: 2 }}>
          <IconButton onClick={handleLike} sx={{ marginRight: 3 }}>
            <ThumbUp sx={{ fontSize: 30, color: isLiked ? "blue" : "gray" }} />
          </IconButton>
          <Typography variant="body2" sx={{ marginRight: 6 }}>
            {likesCount} Likes
          </Typography>

          <IconButton onClick={handleFollow} sx={{ marginRight: 3 }}>
            <Person
              sx={{ fontSize: 30, color: isFollowing ? "blue" : "gray" }}
            />
          </IconButton>
          <Typography variant="body2">{followersCount} Followers</Typography>
        </Box>

        {/* Call to Action */}
        <Grid container spacing={3} sx={{ mt: 4, justifyContent: "center" }}>
          <Grid item>
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
              Contact Us
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Email: {business.contactPerson.email}
            </Typography>
            <Typography variant="body2" color="textSecondary">
              Phone: {business.phone}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h6" sx={{ fontWeight: "bold", mb: 1 }}>
              Address
            </Typography>
            <Typography variant="body2" color="textSecondary">
              {business.address.street}, {business.address.city}{" "}
              {business.address.state} {business.address.zipCode}
            </Typography>
          </Grid>
        </Grid>

        {/* Follow Button */}
        <Box sx={{ mt: 3 }}>
          <Button
            variant="contained"
            color="primary"
            sx={{ width: "100%" }}
            onClick={handleFollow}
          >
            {isFollowing ? "Unfollow Business" : "Follow Business"}
          </Button>
        </Box>
      </Paper>
    </Container>
  );
};

export default PublicBusinessPage;
