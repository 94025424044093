import React, { useContext, useEffect, useState } from "react";
import {
  Container,
  Box,
  Typography,
  Avatar,
  Card,
  CardContent,
  CardMedia,
  Button,
  Divider,
  Grid,
} from "@mui/material";
import { getAuth, signOut } from "firebase/auth";
import { AuthContext } from "./../../firebase/AuthContext";
import { Link, useNavigate } from "react-router-dom";
import AdminDashboard from "./components/Admin/admingDashboard";
import Dashboard from "./Dashboard";

const Profile = () => {
  const { user } = useContext(AuthContext);
  const [favorites, setFavorites] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!user) {
      navigate("/login");
    }
  }, [user, navigate]);

  const auth = getAuth();
  const handleLogout = () => {
    signOut(auth)
      .then(() => {
        window.location.reload();
      })
      .catch((error) => console.error("Error signing out:", error));
  };

  return (
    <Container maxWidth="lg" sx={{ py: 6 }}>
      {/* Profile Header */}
      {user && (
        <Box>
          {user.isAdmin && <AdminDashboard />}
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              flexDirection: { xs: "column", sm: "row" },
              textAlign: { xs: "center", sm: "left" },
              mb: 6,
            }}
          >
            <Avatar
              alt={user.firstName + " " + user.lastName}
              src={user.profilePicture}
              sx={{
                width: { xs: 80, sm: 120 },
                height: { xs: 80, sm: 120 },
                mb: { xs: 2, sm: 0 },
                border: "4px solid #1E88E5",
                boxShadow: "0 4px 10px rgba(0, 0, 0, 0.2)",
              }}
            />
            <Box
              sx={{ ml: { sm: 4 }, textAlign: { xs: "center", sm: "left" } }}
            >
              <Typography
                variant="h4"
                fontWeight="bold"
                color="primary"
                gutterBottom
                sx={{ fontSize: { xs: "1.5rem", sm: "2rem" } }}
              >
                {user.firstName + " " + user.lastName}
              </Typography>
              <Typography variant="body1" color="text.secondary">
                {user.email}
              </Typography>
              <Button
                variant="contained"
                color="error"
                sx={{
                  mt: 2,
                  px: { xs: 2, sm: 4 },
                  py: 1,
                  borderRadius: "20px",
                  fontSize: { xs: "0.8rem", sm: "1rem" },
                }}
                onClick={handleLogout}
              >
                Logout
              </Button>
            </Box>
          </Box>
          <Divider sx={{ my: 4 }} />
        </Box>
      )}

      {/* Dashboard Section */}
      <Dashboard />

      {/* Favorites Section */}
      <Box>
        <Typography
          variant="h5"
          fontWeight="bold"
          gutterBottom
          sx={{ fontSize: { xs: "1.25rem", sm: "1.5rem" } }}
        >
          Your Favorites
        </Typography>
        {favorites.length === 0 ? (
          <Typography variant="body1" color="text.secondary">
            You have no favorites yet.
          </Typography>
        ) : (
          <Grid container spacing={4}>
            {favorites.map((favorite) => (
              <Grid item xs={12} sm={6} md={4} key={favorite.id}>
                <Card
                  elevation={4}
                  sx={{
                    borderRadius: "15px",
                    transition: "transform 0.3s ease, box-shadow 0.3s ease",
                    boxShadow: "0 4px 15px rgba(0, 0, 0, 0.2)",
                    "&:hover": {
                      transform: "scale(1.05)",
                      boxShadow: "0 6px 20px rgba(0, 0, 0, 0.3)",
                    },
                  }}
                >
                  <CardMedia
                    component="img"
                    height="200"
                    image={favorite.imageUrl}
                    alt={favorite.title}
                    sx={{
                      borderRadius: "15px 15px 0 0",
                      height: { xs: 150, sm: 200 },
                    }}
                  />
                  <CardContent>
                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      gutterBottom
                      sx={{ fontSize: { xs: "1rem", sm: "1.25rem" } }}
                    >
                      {favorite.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      gutterBottom
                      sx={{ fontSize: { xs: "0.8rem", sm: "1rem" } }}
                    >
                      {favorite.description}
                    </Typography>
                    <Button
                      variant="outlined"
                      color="error"
                      fullWidth
                      sx={{
                        borderRadius: "20px",
                        mt: 2,
                        fontSize: { xs: "0.8rem", sm: "1rem" },
                        px: { xs: 1, sm: 2 },
                        py: { xs: 0.5, sm: 1 },
                      }}
                    >
                      Remove from Favorites
                    </Button>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Container>
  );
};

export default Profile;
