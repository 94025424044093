import React from "react";
import { Container, Typography, Box, Button, Grid, Paper } from "@mui/material";
import { Link } from "react-router-dom";
import PurchaseComponent from "../components/purchase/PurchaseComponent";

function Presale({
  userFirstName,
  userLastName,
  property,
  amount,
  price,
  priceUnit,
  option,
  description,
  userId,
  propertyId,
}) {
  if (!property) {
    return (
      <Container
        maxWidth="md"
        sx={{ py: { xs: 4, sm: 8 }, textAlign: "center" }}
      >
        <Typography variant="h4" sx={{ fontWeight: 700, color: "black" }}>
          Propiedad no encontrada
        </Typography>
      </Container>
    );
  }

  return (
    <Container maxWidth="md" sx={{ py: { xs: 4, sm: 8 } }}>
      {/* Header */}
      <Typography
        variant="h4"
        sx={{
          fontWeight: 700,
          fontSize: { xs: "2rem", sm: "2.5rem" },
          textAlign: "center",
          marginBottom: 4,
          color: "black",
        }}
      >
        ¡Participa en la Preventa de {property.title}!
      </Typography>
      <Typography
        variant="body1"
        sx={{
          textAlign: "center",
          marginBottom: 6,
          fontSize: { xs: "1rem", sm: "1.25rem" },
        }}
      >
        Asegura tu terreno antes del lanzamiento oficial y disfruta de precios
        exclusivos. Aprovecha esta oportunidad única para ser de los primeros
        inversionistas y obtener una ventaja significativa.
      </Typography>

      {/* Details */}
      <Paper
        elevation={3}
        sx={{
          padding: { xs: 4, sm: 6 },
          backgroundColor: "black",
          borderRadius: 4,
        }}
      >
        <Typography
          variant="h4"
          sx={{
            fontWeight: 700,
            fontSize: { xs: "1.5rem", sm: "2rem" },
            marginBottom: 3,
            color: "white",
          }}
        >
          Detalles de la Preventa
        </Typography>
        <Box sx={{ marginBottom: 4 }}>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            <strong>📌 Precio durante la preventa:</strong> $
            {property.presalePrice || "15,000"} por parcela de 464 metros
            cuadrados.
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            <strong>📌 Precio después de la preventa:</strong> $
            {property.regularPrice || "25,000"} por parcela de 464 metros
            cuadrados.
          </Typography>
          <Typography variant="body1" sx={{ marginBottom: 2 }}>
            <strong>📌 Compra mínima:</strong> {property.minPurchase || 1}{" "}
            parcela.
          </Typography>
          <Typography variant="body1">
            <strong>📌 Ahorro potencial:</strong> Con la preventa, puedes
            ahorrar hasta{" "}
            <strong>
              ${property.regularPrice - property.presalePrice || 10000}
            </strong>{" "}
            en una compra mínima de 1 parcela.
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <Paper
              elevation={1}
              sx={{
                padding: 3,
                borderRadius: 2,
                textAlign: "center",
                backgroundColor: "lightgreen",
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 700, color: "black" }}>
                Precio de Preventa
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontSize: "1.25rem", color: "black" }}
              >
                ${property.presalePrice || "15,000"} / por parcela
              </Typography>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6}>
            <Paper
              elevation={1}
              sx={{
                padding: 3,
                borderRadius: 2,
                textAlign: "center",
                backgroundColor: "red",
              }}
            >
              <Typography variant="h6" sx={{ fontWeight: 700, color: "black" }}>
                Precio Regular
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontSize: "1.25rem", color: "white" }}
              >
                ${property.regularPrice || "25,000"} / por parcela
              </Typography>
            </Paper>
          </Grid>
        </Grid>
      </Paper>

      {/* Call to Action */}
      <Box
        sx={{
          textAlign: "center",
          marginTop: 6,
        }}
      >
        <Typography
          variant="h5"
          sx={{
            fontWeight: 700,
            fontSize: { xs: "1.25rem", sm: "1.5rem" },
            marginBottom: 3,
          }}
        >
          ¡No pierdas la oportunidad de asegurar tu terreno a precios
          exclusivos!
        </Typography>
      </Box>
    </Container>
  );
}

export default Presale;
