import React, { useState, useEffect, useContext } from "react";
import { AuthContext } from "../../../firebase/AuthContext";
import axios from "axios";
import {
  Container,
  Typography,
  Card,
  CardContent,
  CardActions,
  Button,
  List,
  ListItem,
  ListItemText,
  CircularProgress,
} from "@mui/material";
import { Link } from "react-router-dom";

const MessagesByProperty = () => {
  const { user, getToken } = useContext(AuthContext);
  const [properties, setProperties] = useState([]); // Ensure it's always an array
  const [selectedProperty, setSelectedProperty] = useState(null);
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  // Fetch properties
  useEffect(() => {
    const fetchProperties = async () => {
      if (!user) return;

      setLoading(true);
      try {
        const userId = user._id;
        const token = await getToken();
        const response = await axios.get(`/api/property/user/${userId}`, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });

        // Ensure response data is an array
        const propertiesData = Array.isArray(response.data)
          ? response.data
          : [];
        setProperties(propertiesData);

        if (!Array.isArray(response.data)) {
          console.error(
            "Properties API returned non-array data:",
            response.data
          );
        }
      } catch (err) {
        console.error("Failed to fetch properties:", err);
        setError("Failed to load properties.");
      } finally {
        setLoading(false);
      }
    };

    fetchProperties();
  }, [user, getToken]);

  // Fetch messages
  const fetchMessages = async (propertyId) => {
    setLoading(true);
    try {
      const token = await getToken(); // Get the token for authentication
      const response = await axios.get(`/api/messages/getByProperty`, {
        params: { propertyId }, // Pass propertyId as a query parameter
        headers: {
          Authorization: `Bearer ${token}`, // Include the token in the headers
        },
      });
      setMessages(response.data.messages || []); // Set messages from the response
      setSelectedProperty(propertyId);
    } catch (err) {
      console.error("Failed to fetch messages:", err);
      setError("Failed to load messages.");
    } finally {
      setLoading(false);
    }
  };

  if (!user) {
    return (
      <Container>
        <Typography variant="h5" align="center" color="textSecondary">
          Please log in to view messages.
        </Typography>
      </Container>
    );
  }
  console.log("messages ", messages);
  return (
    <Container sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Messages for Your Properties
      </Typography>

      {error && (
        <Typography variant="body1" color="error" align="center">
          {error}
        </Typography>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-around",
          flexWrap: "wrap",
        }}
      >
        {properties.length > 0 ? (
          properties.map((property) => (
            <Card
              key={property._id}
              sx={{
                width: 300,
                m: 2,
                transition: "0.3s",
                boxShadow:
                  selectedProperty === property._id
                    ? "0px 4px 10px rgba(0, 0, 0, 0.2)"
                    : "none",
                "&:hover": { boxShadow: "0px 6px 15px rgba(0, 0, 0, 0.3)" },
              }}
            >
              <CardContent>
                <Typography variant="h6">
                  {property.title || "Untitled Property"}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  {property.description || "No description available"}
                </Typography>
              </CardContent>
              <CardActions>
                <Button
                  style={{ width: "100%" }}
                  color="primary"
                  onClick={() => fetchMessages(property._id)}
                >
                  Ver Mensajes
                </Button>
              </CardActions>
              <CardActions>
                <Button
                  component={Link}
                  to={`/parcels/${property._id}/${property.coordinates.Longitude}/${property.coordinates.Latitude}`}
                  variant="contained"
                  style={{ width: "100%" }}
                >
                  Editar
                </Button>
              </CardActions>
            </Card>
          ))
        ) : (
          <Typography variant="body1" color="textSecondary">
            You have no listed properties.
          </Typography>
        )}
      </div>

      {loading && <CircularProgress sx={{ mt: 4 }} />}

      {!loading && selectedProperty && (
        <List sx={{ mt: 4, boxShadow: 3, borderRadius: 2 }}>
          <Typography variant="h5" align="center" gutterBottom>
            Messages
          </Typography>
          {messages.map((message) => (
            <ListItem key={message._id} divider>
              <ListItemText
                primary={
                  message.senderId === user._id
                    ? `You: ${message.content}`
                    : `Buyer: ${message.content}`
                }
                secondary={`Sent on: ${new Date(
                  message.createdAt
                ).toLocaleString()}`}
              />
              <Button
                component={Link}
                to={`/messages/${message.parcelId}/${message.senderId}/${message.receiverId}/${message.propertyId}`}
                variant="contained"
                color="primary"
              >
                Open Chat
              </Button>
            </ListItem>
          ))}
        </List>
      )}

      {!loading && !selectedProperty && properties.length > 0 && (
        <Typography
          variant="body1"
          align="center"
          color="textSecondary"
          sx={{ mt: 4 }}
        >
          Select a property to view its messages.
        </Typography>
      )}
    </Container>
  );
};

export default MessagesByProperty;
